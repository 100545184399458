const MENU_IDS = [
  'calendar',
  'schemats',
  'surveys',
  'patients',
  'forms',
  'notifications',
  'employees',
  'medicines',
  'symptoms',
  'reports',
  'costs-estimate',
  'other-reports',
] as const;

const ROLES = [
  'coordinator',
  'nurse',
  'medic',
  'super_admin',
  'admin',
  'patient',
  'sponsor',
] as const;

type MenuId = (typeof MENU_IDS)[number];
type Role = (typeof ROLES)[number];

const ROLE_CONFIG = {
  menu: {
    coordinator: MENU_IDS,
    nurse: MENU_IDS,
    medic: MENU_IDS,
    super_admin: MENU_IDS,
    admin: MENU_IDS,
    patient: MENU_IDS,
    sponsor: ['reports'] as const,
  } as const,
  chat: {
    coordinator: true,
    nurse: true,
    medic: true,
    super_admin: false,
    admin: false,
    patient: true,
    sponsor: false,
  },
  notifications: {
    coordinator: true,
    nurse: true,
    medic: true,
    super_admin: false,
    admin: false,
    patient: true,
    sponsor: false,
  },
  patientsInReports: {
    coordinator: true,
    nurse: true,
    medic: true,
    super_admin: false,
    admin: false,
    patient: false,
    sponsor: false,
  },
} as const;

export function getRoleMenu(role: string): readonly MenuId[] {
  if (!ROLES.includes(role as Role)) {
    console.error(`Role ${role} is not supported`);
    return [];
  }

  return ROLE_CONFIG.menu[role as Role] || [];
}

export function isRoleChat(role: string) {
  return ROLE_CONFIG.chat[role as Role] || false;
}

export function isRoleNotifications(role: string) {
  return ROLE_CONFIG.notifications[role as Role] || false;
}

export function isRolePatientsInReports(role: string) {
  return ROLE_CONFIG.patientsInReports[role as Role] || false;
}
